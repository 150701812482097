<a href="javascript:;" *ngIf="!FeedbackOpen" (click)="sendFeedback(content)"><img src="assets/img/feedback.png"></a>

<ng-template #content let-c="close" let-d="dismiss">
    <form role="form" class="form form-horizontal" name="Ticket" (ngSubmit)="f.form.valid && submitfeedback()" #f="ngForm" >
        <div class="modal-body px-4">
            <h4 class="font-weight-bold" *ngIf="page_name">How satisfied are you with our {{ page_name }} services?</h4>
            <h4 class="font-weight-bold" *ngIf="!page_name">How satisfied are you with our services?</h4>

            <div class="form-group d-flex feedback-ratings">
                <div class="item shadow-sm border extremely-satisfied" [ngClass]="(FBModel.Rating == 'Extremely Satisfied') ? 'active-rating' : ''" (click)="setRating('Extremely Satisfied')">
                    <div class="card shadow-none">
                        <div class="card-body p-0">
                            <img src="assets/img/extremely-satisfied.png">
                            <div class="clearfix"></div>
                            <p>Extremely Satisfied</p>
                        </div>
                    </div>
                </div>
                <div class="item shadow-sm border satisfied" [ngClass]="(FBModel.Rating == 'Satisfied') ? 'active-rating' : ''" (click)="setRating('Satisfied')">
                    <div class="card shadow-none">
                        <div class="card-body p-0">
                            <img src="assets/img/satisfied.png">
                            <div class="clearfix"></div>
                            <p>Satisfied</p>
                        </div>
                    </div>
                </div>
                <div class="item shadow-sm border neutral" [ngClass]="(FBModel.Rating == 'Neutral') ? 'active-rating' : ''" (click)="setRating('Neutral')">
                    <div class="card shadow-none">
                        <div class="card-body p-0">
                            <img src="assets/img/neutral.png">
                            <div class="clearfix"></div>
                            <p>Neutral</p>
                        </div>
                    </div>
                </div>
                <div class="item shadow-sm border unsatisfied" [ngClass]="(FBModel.Rating == 'Unsatisfied') ? 'active-rating' : ''" (click)="setRating('Unsatisfied')">
                    <div class="card shadow-none">
                        <div class="card-body p-0">
                            <img src="assets/img/unsatisfied.png">
                            <div class="clearfix"></div>
                            <p>Unsatisfied</p>
                        </div>
                    </div>
                </div>
                <div class="item shadow-sm border extremely-unsatisfied" [ngClass]="(FBModel.Rating == 'Extremely Unsatisfied') ? 'active-rating' : ''" (click)="setRating('Extremely Unsatisfied')">
                    <div class="card shadow-none">
                        <div class="card-body p-0">
                            <img src="assets/img/extremely-unsatisfied.png">
                            <div class="clearfix"></div>
                            <p>Extremely Unsatisfied</p>
                        </div>
                    </div>
                </div>

            </div>
            <div class="form-group">
                <label class="label-control text-left">Your Feedback/Recommendations (Max 500 Characters)</label>
                <textarea class="form-control" maxlength="500" name="message" rows="5" [(ngModel)]="FBModel.Feedback" #Feedback="ngModel" name="Feedback"></textarea>
            </div>
        </div>
        <div class="modal-footer px-4 justify-content-start">
            <button type="submit" class="btn btn-raised btn-primary rounded-0"><i class="ft-check valign-middle"></i> Submit</button>
            <button type="button" class="btn btn-raised btn-secondary rounded-0" id="cancel" (click)="d()"><i class="ft-x valign-middle"></i> Cancel</button>
        </div>
    </form>
</ng-template>
