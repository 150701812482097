import { Component, Output, ViewChild, EventEmitter, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from '../services/layout.service';
import { ActivatedRoute,Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConfigService } from '../services/config.service';
import { CustomService } from 'app/custom.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'environments/environment';
import { PageService } from 'app/pagestate.service';

@Component({
    selector: "app-navbar",
    templateUrl: "./navbar.component.html",
    styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {
    API = environment;
    CandidateId: number;
    EmailVerified: any;
    Notifications: any;
    Alerts: any;

    @ViewChild('AnnouncementModal') AnnouncementModal: any;
    ModalOpen: boolean = false;
    Announcement: any;
    modalReference:any;
    modalOption: NgbModalOptions = {};
    closeResult: string;

    NotiCount: number = 0;
    returnUrl: any;

    currentLang = "en";
    candidate_name:any;
    toggleClass = "ft-maximize";
    placement = "bottom-right";
    public isCollapsed = true;
    layoutSub: Subscription;
    @Output()
    toggleHideSidebar = new EventEmitter<Object>();
    isPremium: boolean = false;
    PremiumExpired : any;
    ProfilePic: any;

    public config: any = {};
    show_alert_box : boolean = true;

    openNotebar : boolean = false; // mohit add
    lecture_id : any;
    show_addnote_form : boolean = false;
    allnotes:any;
    availableNotes:boolean = false;

    saveNotes = {
		"NoteTitle":"",
		"NoteDescription":"",
        "CandidateId" : 0,
        "Lecture_id" : "",
        "NoteId" : "",
    };

    constructor(
        private api: CustomService,
        public translate: TranslateService,
        private layoutService: LayoutService,
        public router : Router,
        private modalService: NgbModal,
        private configService:ConfigService,
        private pageservice : PageService
    )
    {

        const browserLang: string = translate.getBrowserLang();
        translate.use(browserLang.match(/en|es|pt|de/) ? browserLang : "en");

        let customerInfo = JSON.parse(localStorage.getItem('candidateInfo'));
        this.candidate_name = customerInfo.FirstName;
        this.ProfilePic = customerInfo.ProfilePic;
        this.CandidateId = customerInfo.CustomerId;

        if(customerInfo.isPremium == 1) {
            this.isPremium = true;

            this.api.premiumclubdays(this.CandidateId).subscribe(
                (data:any)=>{
                    if( parseInt( data ) == data && data > 0 ){
                        this.PremiumExpired = data;
                        this.isPremium = true;
                    }else{
                        this.isPremium = false;
                    }
                },(errors:any)=>{

                }
            );
        }

        this.saveNotes.CandidateId = this.CandidateId;
        this.EmailVerified = customerInfo.isVerified;

        this.layoutSub = layoutService.changeEmitted$.subscribe(
            direction => {
                const dir = direction.direction;
                if (dir === "rtl") {
                    this.placement = "bottom-left";
                } else if (dir === "ltr") {
                    this.placement = "bottom-right";
                }
            });


            this.pageservice.NoteState.subscribe(
                (lstate:any)=>{
                    if(lstate){
                        if(lstate.note_action){
                            this.openNotebar = true;
                            this.lecture_id = lstate.lecture_id;
                            this.saveNotes.Lecture_id = this.lecture_id;
                            this.getmynotes();
                        }
                    }
                }
            );
        }

        ngOnInit() {
            this.config = this.configService.templateConf;



        }

        getmynotes(){
            this.api.allnotes(this.CandidateId,this.lecture_id).subscribe(
                (data:any)=>{
                    if(data.length > 0){
                        this.allnotes = data;
                        this.availableNotes = true;
                    }else{
                        this.availableNotes = false;
                    }

                },(errors:any)=>{

                }
            );
        }

        ngAfterViewInit() {
            if(this.config.layout.dir) {
                setTimeout(() => {
                    const dir = this.config.layout.dir;
                    if (dir === "rtl") {
                        this.placement = "bottom-left";
                    } else if (dir === "ltr") {
                        this.placement = "bottom-right";
                    }
                }, 0);

                setInterval(() => { this.getNotifications(); }, 15000);

            }
        }

        getNotifications() {
            this.api.getNotifications(this.CandidateId,0).subscribe((data: any) => {

                this.Notifications = data.notifications;
                this.NotiCount = this.Notifications.length;
                this.Alerts = data.alerts;

                let c = JSON.parse(localStorage.getItem('candidateInfo'));
                c.ProfileApprovalRequest = this.Alerts[0].ProfileApprovalRequest;
                c.ProfileApproved = this.Alerts[0].ProfileApproved;
                localStorage.setItem('candidateInfo',JSON.stringify(c));

                this.Announcement = data.announcement;
                if(this.Announcement != 0) {
                    if(!this.ModalOpen) {
                        this.openAnnouncementModal();
                    }
                }
            },(error)=>{

            });
        }

        readNotification(NotificationId) {
            this.api.readNotification(this.CandidateId,NotificationId).subscribe((data: any) => {
                this.getNotifications();
            },(error)=>{

            });
        }

        ngOnDestroy() {
            if (this.layoutSub) {
                this.layoutSub.unsubscribe();
            }
        }

        ChangeLanguage(language: string) {
            this.translate.use(language);
        }

        ToggleClass() {
            if (this.toggleClass === "ft-maximize") {
                this.toggleClass = "ft-minimize";
            } else {
                this.toggleClass = "ft-maximize";
            }
        }

        toggleNotificationSidebar() {
            this.layoutService.emitNotiSidebarChange(true);
        }

        toggleSidebar() {
            const appSidebar = document.getElementsByClassName("app-sidebar")[0];
            if (appSidebar.classList.contains("hide-sidebar")) {
                this.toggleHideSidebar.emit(false);
            } else {
                this.toggleHideSidebar.emit(true);
            }
        }

        logout() {
            let data = { CandidateId : this.CandidateId };
            // here we update logout time
            this.api.logout(data).subscribe(
                (data:any)=>{

                },(errors:any)=>{

                }
            );
            // End
            localStorage.removeItem('candidateInfo');
            this.returnUrl = "/";
            this.router.navigate([this.returnUrl]);
        }

        private getDismissReason(reason: any): string {
            this.ModalOpen = false;
            if (reason === ModalDismissReasons.ESC) {
                return 'by pressing ESC';
            } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
                return 'by clicking on a backdrop';
            } else {
                return `with: ${reason}`;
            }
        }

        openAnnouncementModal() {
            this.ModalOpen = true;
            this.modalOption.backdrop = 'static';
            this.modalOption.keyboard = false;
            this.modalReference = "";
            this.modalReference = this.modalService.open(this.AnnouncementModal);
            this.modalReference.result.then((result) => {
                this.closeResult = `Closed with: ${result}`;
            }, (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
        }

        savenotes(){
            this.api.savenotes(this.saveNotes).subscribe(
                (data:any)=>{
                    this.show_addnote_form = false;
                    this.getmynotes();
                },(errors:any)=>{
                    this.show_addnote_form = true;
                }
            );
        }

        editnote(note_id){
            this.api.getnotedetail(note_id).subscribe(
                (data:any)=>{
                    this.saveNotes.NoteTitle = data.NoteTitle;
                    this.saveNotes.NoteDescription = data.NoteDesc;
                    this.saveNotes.NoteId = data.NoteId;
                    this.show_addnote_form = true;
                },(errors:any)=>{

                }
            );
        }

        removenote(note_id){
            this.api.deletenote(note_id).subscribe(
                (data:any)=>{
                    this.getmynotes();
                },(errors:any)=>{

                }
            );
        }

        announcementApproval(Status,AnnouncementId) {
            this.api.announcementApproval(this.CandidateId,Status,AnnouncementId).subscribe((data: any) => {
                this.modalReference.close();
            },(error)=>{

            });
        }
    }
