export const environment = {
    production: true,
    api               : "https://burlyinternational.co.uk/admin/api/gateway/",
    uploads           : "https://burlyinternational.co.uk/admin/uploads/",
    userphotoes       : "https://burlyinternational.co.uk/admin/uploads/users/",
    candidatephotoes  : "https://burlyinternational.co.uk/admin/uploads/profile_pictures/",
    ticketattachefile : "https://burlyinternational.co.uk/admin/uploads/tickets/",
    idcardfile        : "https://burlyinternational.co.uk/admin/uploads/icards/",
    enrollmentlatter  : "https://burlyinternational.co.uk/admin/uploads/admissions/",
    receipt           : "https://burlyinternational.co.uk/admin/uploads/receipts/",
    marksheet         : "https://burlyinternational.co.uk/admin/uploads/marksheets/",
    certificate       : "https://burlyinternational.co.uk/admin/uploads/certificates/",
    transcript        : "https://burlyinternational.co.uk/admin/uploads/transcript/",
	coursedocs        : "https://burlyinternational.co.uk/admin/uploads/course_docs/",
	lectures          : "https://burlyinternational.co.uk/admin/uploads/lectures/",
	webinars          : "https://burlyinternational.co.uk/admin/uploads/webinars/",
    employer_logos    : "https://burlyinternational.co.uk/admin/uploads/employer_logos/",
    quotes            : "https://burlyinternational.co.uk/admin/uploads/quotes/",
    trainings         : "https://burlyinternational.co.uk/admin/uploads/trainings/",
    candidatedocs     : "https://burlyinternational.co.uk/admin/uploads/candidate_docs/",
    front_url         : "https://portal.burlyinternational.co.uk/",
    angular_url       : "https://portal.burlyinternational.co.uk/",
    main_url          : "https://portal.burlyinternational.co.uk/",
    media_path :       'https://burlyinternational.co.uk/admin/uploads/media/',
    video_path :       'https://burlyinternational.co.uk/admin/uploads/courcevideo/',
    lessons :          'https://burlyinternational.co.uk/admin/uploads/lesson/',
    profilepic :        'https://burlyinternational.co.uk/admin/uploads/customers/',
};
