import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
    returnUrl: any;
    constructor(private authService: AuthService, public router : Router) {}

    // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // if(!this.authService.isAuthenticated()) {
            // this.returnUrl = "/pages/login";
            // this.router.navigate([this.returnUrl]);
        // }else {
            // return true;
        // }
    // }
	
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		if(localStorage.getItem('candidateInfo')){
			return true;
		}else {
			this.router.navigate(['/pages/login'], {
				queryParams: {
				  return: state.url
				}
			});
		}
	}
}
