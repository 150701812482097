import { Injectable, PLATFORM_ID,Inject } from '@angular/core';
import { isPlatformServer, isPlatformBrowser } from '@angular/common';
import { ActivePageState } from 'app/activePageState';
import { NotesectionStateState } from 'app/notesectionState';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PageService {

    private cartSubject = new Subject<ActivePageState>();
    CartState = this.cartSubject.asObservable();

    // for notes observable
    private noteSection = new Subject<NotesectionStateState>();
    NoteState = this.noteSection.asObservable();
    // End



    constructor(
        @Inject(PLATFORM_ID) private platformId: Object
    ) {

    }

    setPageobserval(page_name){
        this.cartSubject.next({page_name : page_name});
    }

    setNoteobserval(action,lecture_id){
        this.noteSection.next({note_action : action,lecture_id: lecture_id});
    }

















}
