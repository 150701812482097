import { Component, OnInit, HostBinding } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CustomService } from '../../custom.service';
import { NGXToastrService } from 'app/components/extra/toastr/toastr.service';
import { environment } from '../../../environments/environment';

import { PageService } from 'app/pagestate.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit {
    env : any;
    currentDate : Date = new Date();
    closeResult: string;
    FBModel :any = {
        'Rating': '',
        'Feedback': '',
        'CandidateId': '',
        'PageType' : ''
    };

    FeedbackOpen: boolean = false;

    page_name :  any;
    constructor(
        private modalService: NgbModal,
        private api : CustomService,
        public toastr: NGXToastrService,
        private pageservice : PageService
    ) {

        this.pageservice.CartState.subscribe(
            (lstate:any)=>{
                if(lstate){
                    this.page_name = lstate.page_name;
                    this.FBModel.PageType = this.page_name;
                }
            }
        );

    }

    ngOnInit(): void {
        this.FBModel.CandidateId = this.api.getCustomerId();

    }

    submitfeedback(){
        if(this.FBModel.Rating == '' && this.FBModel.Feedback == '') {
            this.toastr.typeError('You cannot submit blank Feedback');
        }else {
            this.api.submitfeedback(this.FBModel).subscribe(
                (data:any)=>{
                    if(data == 1) {
                       this.toastr.typeSuccess('Feedback submitted successfully');
                    }else {
                       this.toastr.typeError('Error in submitting your feedback. Try again later.');
                    }
                    this.FBModel.Rating = '';
                    this.FBModel.Feedback = '';
                    let element: HTMLElement = document.getElementById('cancel') as HTMLElement;
                    element.click();
                },(errors:any)=>{

                }
            )
        }
    }

    setRating(Rating) {
        this.FBModel.Rating = Rating;
    }

    sendFeedback(content) {
        this.FeedbackOpen = true;
        this.modalService.open(content, { size: 'lg' }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        this.FeedbackOpen = false;
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }

    }
}
