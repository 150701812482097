import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from './../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CustomService {
    API = environment.api;
    UPLOADS = environment.uploads;
    headers = new HttpHeaders();

    CandidateInfo: any = {};
    CandidateId: number = 0;
    PermtCountryId: number = 0;

    constructor(private http: HttpClient) {
        this.headers.append('Content-Type', 'text/plain');
        this.headers.append('Access-Control-Allow-Origin', '*');
        this.headers.append('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, PATCH, DELETE');
        this.headers.append('Access-Control-Allow-Headers', "Origin, X-Requested-With, Content-Type, Accept, x-auth");
	}

     get_cource_questions(CourseId : any){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.get(this.API+'get_course_questions/'+CourseId,{headers:headers});
    }
    
    ExamRequest(CustomerId:any,CourseId:any) {
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.get(this.API+'getExamRequest/'+CustomerId+'/'+CourseId,{headers:headers});
    }
    

      checkResult(Result:any,ExamId:any){
      let headers = new HttpHeaders();
      headers.append('Access-Control-Allow-Origin','*');
      return this.http.post(this.API+'submitexam/'+ExamId,Result,{headers:headers});
     }


	getExchangeRate() {
		return this.http.get(this.API+'getExchangeRate',{headers:this.headers});
	}

    getStates(){
        return this.http.get(this.API+'getStates',{headers:this.headers});
    }

      checkMcqResult(Result:any,CustomerId:any,IndexId:any,CourseId:any){
      let headers = new HttpHeaders();
      headers.append('Access-Control-Allow-Origin','*');
      return this.http.post(this.API+'mcqresult/'+CustomerId+'/'+IndexId+'/'+CourseId,Result,{headers:headers});
    }

	registrationFeesPaid_RazorPay(Res,Opts,CandidateId) {
		let data = {
			'res':Res,
			'Opts': Opts
		}
		return this.http.post(this.API+'registrationFeesPaid_RazorPay/'+CandidateId,data,{headers:this.headers});
	}

	FeesPaid_RazorPay(Res,Opts,CandidateId) {
		let data = {
			'res':Res,
			'Opts': Opts
		}
		return this.http.post(this.API+'feesPaid_RazorPay/'+CandidateId,data,{headers:this.headers});
	}

	ReExamFeesPaid_RazorPay(Res,Opts,CandidateId) {
		let data = {
			'res':Res,
			'Opts': Opts
		}
		return this.http.post(this.API+'reexamFeesPaid_RazorPay/'+CandidateId,data,{headers:this.headers});
	}

	reEvaluationFeesPaid_RazorPay(Res,Opts,CandidateId) {
    	let data = {
			'res':Res,
			'Opts': Opts
		}
		return this.http.post(this.API+'reEvaluationFeesPaid_RazorPay/'+CandidateId,data,{headers:this.headers});
    }

	getCustomerInfo() {
        if(localStorage.getItem('candidateInfo')){
            return JSON.parse(localStorage.getItem('candidateInfo'));
        }
    }



    getCustomerId() {
        if(localStorage.getItem('candidateInfo')){
            let customer = JSON.parse(localStorage.getItem('candidateInfo'));
            return customer.CustomerId;
        }
    }

    getCustomerName() {
        if(localStorage.getItem('candidateInfo')){
            let customer = JSON.parse(localStorage.getItem('candidateInfo'));
            return customer.FirstName + ' ' + customer.LastName;
        }
    }

    getPermtCountryId() {
    	if(localStorage.getItem('candidateInfo')){
            let customer = JSON.parse(localStorage.getItem('candidateInfo'));
            return customer.PermtCountryId;
        }
    }

    login(data){
        return this.http.post(this.API+'login',data,{headers:this.headers});
    }

	getCountries() {
		return this.http.get(this.API+'getCountries/',{headers:this.headers});
	}

	getIndustries() {
		return this.http.get(this.API+'getIndustries/',{headers:this.headers});
	}

	getFunctionalAreas() {
		return this.http.get(this.API+'getFunctionalAreas/',{headers:this.headers});
	}

    getProfile() {
		let CandidateId = this.getCustomerId();
		return this.http.get(this.API+'profile/'+CandidateId,{headers:this.headers});
    }

    saveProfile(info, data) {
        return this.http.post(this.API+info, JSON.stringify({
            data
        }), {headers : this.headers});
    }

	getCourseSummary(CourseId) {
		return this.http.get(this.API+'getCourseSummary/'+CourseId,{headers:this.headers});
	}

	getCourseTitle(CourseId) {
		return this.http.get(this.API+'getCourseTitle/'+CourseId,{headers:this.headers});
	}

    deleteProfileData(data) {
        return this.http.post(this.API+'delete_profile_data', JSON.stringify({
            data
        }), {headers : this.headers});
    }

    avaialbeForJob(status){

    }
    Register(data,CourseId){
        return this.http.post(this.API+'register/'+CourseId,data,{headers:this.headers});
    }
 
	saveExpDeclared(CandidateId,TotalExp) {
		return this.http.post(this.API+'saveExpDeclared/'+CandidateId+'/'+TotalExp,{headers:this.headers});
	}

    ForgotPassword(email){
        return this.http.post(this.API+'forgotpassword',email,{headers:this.headers});
    }

	getCourseToEnroll(CandidateId) {
		return this.http.post(this.API+'getCourseToEnroll/'+CandidateId,{headers:this.headers});
	}

	isEnrolled(CourseId, CandidateId) {
		return this.http.post(this.API+'getEnrolledCourses/'+CourseId+'/'+CandidateId,{headers:this.headers});
	}

    // getEnrolledCourses(CourseId, CandidateId) {
    //     return this.http.post(this.API+'getEnrolledCourses/'+CourseId+'/'+CandidateId,{headers:this.headers});
    // }

    getEnrolledCourses(CourseId,CandidateId) {
        return this.http.get(this.API+'getmycourcesdetail/'+CandidateId,{headers:this.headers});
    }

    getRecommendedCourses() {
        let CandidateId = this.getCustomerId();
        return this.http.post(this.API+'getRecommendedCourses/'+CandidateId,{headers:this.headers});
    }

    getPaymentsByCourseId(CourseId,CandidateId) {
        return this.http.post(this.API+'getPaymentsByCourseId/'+CourseId+'/'+CandidateId,{headers:this.headers});
    }

    getReExamsByCourseId(CourseId,CandidateId) {
        return this.http.post(this.API+'getReExamsByCourseId/'+CourseId+'/'+CandidateId,{headers:this.headers});
    }

	updatePayment(CourseId,CandidateId,PaymentData,PCode) {
        return this.http.post(this.API+'updatePayment/'+CourseId+'/'+CandidateId+'/'+PCode,PaymentData,{headers:this.headers});
    }

    makePayment(CourseId,CandidateId,PaymentData,Currency,PaymentThrough) {
        return this.http.post(this.API+'makePayment/'+CourseId+'/'+CandidateId+'/'+Currency+'/'+PaymentThrough,PaymentData,{headers:this.headers});
    }

	ccAvenueWebinarEnrollment(WebinarId,CandidateId,TotalAmount) {
		return this.http.post(this.API+'ccAvenueWebinarEnrollment/'+WebinarId+'/'+CandidateId,TotalAmount,{headers:this.headers});
	}

    processPayment(data) {
        return this.http.post('https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction',data,{headers:this.headers});
    }

    saveTicket(data){
        return this.http.post(this.API+'saveticket',data,{headers:this.headers});
    }

    // replysaveticket(data){
    //     return this.http.post(this.API+'replysaveticket',data,{headers:this.headers});
    // }

    getAllRelatedTo(){
        return this.http.get(this.API+'getTicketRelated',{headers:this.headers});
    }

    // getCandidateTickets(TicketId,CandidateId){
    //     return this.http.post(this.API+'getcandidateTickets/'+TicketId+'/'+CandidateId,{headers:this.headers});
    // }

    // getTicketFollowUp(TicketId){
    //     return this.http.post(this.API+'getFollowupHistory/'+TicketId,{headers:this.headers});
    // }


    // tickets apis//

      getCandidateTickets(CourseId:any,CandidateId:any) {
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.get(this.API+'getCandidateTickets/'+CandidateId,{headers:headers});
    }

     deleteTickes(TicketId:any) {
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.get(this.API+'deleteTickets/'+TicketId,{headers:headers});
    }

    // saveTickets(Data:any,CandidateId:any) {
    //     let headers = new HttpHeaders();
    //     headers.append('Access-Control-Allow-Origin','*');
    //     return this.http.post(this.API+'saveTickets/'+CandidateId,Data,{headers:headers});
    // }
    saveTicketReplay(Data:any) {
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.post(this.API+'saveTicketReplay',Data,{headers:headers});
    }

    getTicketFollowUp(TicketId:any){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.get(this.API+'getFollowupHistory/'+TicketId,{headers:headers});
    }

    // end //


    getCoursewiseIdCard(CourseId,CandidateId){
        return this.http.get(this.API+'getalldownloads/'+CourseId+'/'+CandidateId,{headers:this.headers});
    }

    getSubjectsByCourseId(CourseId,BatchId,IncludeLectures) {
		let CandidateId = this.getCustomerId();
        return this.http.get(this.API+'getCourseSubjects/'+CourseId+'/'+BatchId+'/'+CandidateId+'/'+IncludeLectures,{headers:this.headers});
    }

   get_cource_slug(slug : any,CustomerId:any){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.get(this.API+'get_cource_by_slug/'+slug+'/'+CustomerId,{headers:headers});
    }

     getQuiz(QuizId:any) {
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.get(this.API+'getQuiz/'+QuizId,{headers:headers});
    }
    getQuizQuestion(QuizId:any) {
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.get(this.API+'getQuizQuestion/'+QuizId,{headers:headers});
    }

    requestSemExams(CourseId,Semester,Subjects,ExamDate,CandidatetakeImage) {
		let CandidateId = this.getCustomerId();
        let data = {Subjects:Subjects,CandidatetakeImage:CandidatetakeImage };
        return this.http.post(this.API+'requestSemExams/'+CourseId+'/'+Semester+'/'+ExamDate+'/'+CandidateId,data,{headers:this.headers});
    }

    createQuestionPapers(CourseId,SubjectCodeList) {
    	let data = {
    		CourseId: CourseId,
    		SubjectCodeList: SubjectCodeList
    	}
    	return this.http.post(this.API+'createQuestionPapers',data,{headers:this.headers});
    }

    getProjectInfoByExamCode(ExamCode) {
        return this.http.get(this.API+'getProjectInfoByExamCode/'+ExamCode,{headers:this.headers});
    }

    qpDownloadedByCandidate(CandidateId,CandidateExamId,CandidateImage) {
        let data = { CandidateId:CandidateId,CandidateExamId:CandidateExamId,CandidateImage:CandidateImage };
        return this.http.post(this.API+'qpDownloadedByCandidate',data,{headers:this.headers});
        //return this.http.get(this.API+'qpDownloadedByCandidate/'+CandidateId+'/'+CandidateExamId,{headers:this.headers});
    }

    requestReevaluation(CourseId,SubjectId,CandidateId,BatchId,CandidateExamId,NowOrLater,PaymentThrough) {
        return this.http.post(this.API+'requestReevaluation/'+CourseId+'/'+SubjectId+'/'+CandidateId+'/'+BatchId+'/'+CandidateExamId+'/'+NowOrLater+'/'+PaymentThrough,{headers:this.headers});
    }

    requestReExam(CourseId,SubjectId,CandidateId,BatchId,NowOrLater,PaymentThrough) {
        return this.http.post(this.API+'requestReExam/'+CourseId+'/'+SubjectId+'/'+CandidateId+'/'+BatchId+'/'+NowOrLater+'/'+PaymentThrough,{headers:this.headers});
    }

    getExamHistory(CourseId,CandidateId,Subjects) {
        return this.http.post(this.API+'getExamHistory/'+CourseId+'/'+CandidateId,Subjects,{headers:this.headers});
    }

    getEvaluationHistory(CourseId,CandidateId,Subjects) {
        return this.http.post(this.API+'getEvaluationHistory/'+CourseId+'/'+CandidateId,Subjects,{headers:this.headers});
    }

	saveElectives(Semester,EnrollmentId,Electives) {
		return this.http.post(this.API+'saveElectives/'+Semester+'/'+EnrollmentId,Electives,{headers:this.headers});
	}

	payRegistrationFees(Course,CandidateId,TotalAmount,FeesCurrency,PaymentThrough,TotalRedeem){
        return this.http.post(this.API+'payRegistrationFees/'+CandidateId+'/'+TotalAmount+'/'+FeesCurrency+'/'+PaymentThrough+'/'+TotalRedeem,Course,{headers:this.headers});
    }

	getEntranceDatabank(CandidateId,ExamCode) {
		return this.http.post(this.API+'getEntranceDatabank/'+CandidateId+'/'+ExamCode,{headers:this.headers});
	}

	finishEntranceExam(CandidateId,ExamCode,Submission) {
		return this.http.post(this.API+'finishEntranceExam/'+CandidateId+'/'+ExamCode,Submission,{headers:this.headers});
	}

	scheduleEntranceExam(CandidateId,CourseId) {
		return this.http.post(this.API+'scheduleEntranceExam/'+CandidateId+'/'+CourseId,{headers:this.headers});
	}

	getEntranceAssessment(CandidateId,ExamCode) {
		return this.http.post(this.API+'getEntranceAssessment/'+CandidateId+'/'+ExamCode,{headers:this.headers});
	}

    getNotifications(CandidateId,NotiType) {
        return this.http.post(this.API+'getNotifications/'+CandidateId+'/'+NotiType,{headers:this.headers});
    }

	readNotification(CandidateId,NotificationId) {
        return this.http.post(this.API+'readNotification/'+CandidateId+'/'+NotificationId,{headers:this.headers});
    }

	getTodoGroups(CandidateId) {
		return this.http.get(this.API+'getTodoGroups/'+CandidateId,{headers:this.headers});
	}

	getTodo(CandidateId,GroupId) {
		return this.http.get(this.API+'getTodo/'+CandidateId+'/'+GroupId,{headers:this.headers});
	}

	saveTodo(ToDo) {
		return this.http.post(this.API+'saveTodo',ToDo,{headers:this.headers});
	}
    saveGroup(ToDo){
        return this.http.post(this.API+'saveGroup',ToDo,{headers:this.headers});
    }

	updateTodoStatus(id,status) {
		return this.http.post(this.API+'updateTodoStatus',{'id':id,'status':status},{headers:this.headers});
	}

	deleteTodo(id,CandidateId) {
		return this.http.post(this.API+'deleteTodo',{'id':id,'UserId':CandidateId},{headers:this.headers});
	}

	getLectures(CourseId,SubjectId) {
		let CandidateId = this.getCustomerId();
		return this.http.get(this.API+'getLectures/'+CourseId+'/'+SubjectId+'/'+CandidateId,{headers:this.headers});
	}

	getLectureQuiz(QuizId) {
		return this.http.post(this.API+'getLectureQuiz/'+QuizId,{headers:this.headers});
	}

	finishLectureQuiz(CandidateId,ActiveQuizId,Submission) {
		return this.http.post(this.API+'finishLectureQuiz/'+CandidateId+'/'+ActiveQuizId,Submission,{headers:this.headers});
	}

	markLectureStarted(CandidateId,LectureId) {
		return this.http.post(this.API+'markLectureStarted/'+CandidateId+'/'+LectureId,{headers:this.headers});
	}

	markLectureCompleted(CandidateId,LectureId) {
		return this.http.post(this.API+'markLectureCompleted/'+CandidateId+'/'+LectureId,{headers:this.headers});
	}

	completeLesson(CourseId:any,CustomerId:any,LessonId:any) {
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.get(this.API+'CompleteLesson/'+CourseId+'/'+CustomerId+'/'+LessonId,{headers:headers});
    } 
    
    deleteNote(NoteId:any) {
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.get(this.API+'deleteLectureNotes/'+NoteId,{headers:headers});
    }


	getStudyMaterial(CandidateId,SubjectId,CourseId) {
		return this.http.get(this.API+'getStudymaterialBySubjectid/'+CandidateId+'/'+SubjectId+'/'+CourseId,{headers:this.headers});
	}

	getCourseInfo(CourseId) {
		return this.http.get(this.API+'getCourseInfo/'+CourseId,{headers:this.headers});
	}

	getCourseCategories() {
		return this.http.get(this.API+'getCourseCategories/',{headers:this.headers});
	}

	getCourseTypes() {
		return this.http.get(this.API+'getCourseTypes/',{headers:this.headers});
	}

	searchCourses(data) {
		return this.http.post(this.API+'searchCourses/',data,{headers:this.headers});
	}

	getDegrees() {
		return this.http.get(this.API+'getDegrees/',{headers:this.headers});
	}

	requestProfileApproval(CandidateId) {
		return this.http.post(this.API+'requestProfileApproval/',CandidateId,{headers:this.headers});
	}

	saveElectiveSubjects(Semester,ElectivesChecked,CandidateId,EnrollmentId) {
		return this.http.post(this.API+'saveElectiveSubjects/'+CandidateId+'/'+EnrollmentId+'/'+Semester,ElectivesChecked,{headers:this.headers});
	}

	getLectureNotes(LectureId,CandidateId) {
		return this.http.get(this.API+'getLectureNotes/'+LectureId+'/'+CandidateId,{headers:this.headers});
	}

	saveLectureNote(LectureId,Note,CandidateId) {
		return this.http.post(this.API+'saveLectureNote/'+LectureId+'/'+CandidateId,Note,{headers:this.headers});
	}

	getJobs(searchParams) {
		let params = new HttpParams();
		params = params.append('Keywords', searchParams.Keywords);
		params = params.append('Industry', searchParams.Industry);
		params = params.append('Location', searchParams.Location);
		params = params.append('JobType', searchParams.JobType);
		params = params.append('FunctionalArea', searchParams.FunctionalArea);
		params = params.append('MinExperience', searchParams.MinExperience);
		params = params.append('MaxExperience', searchParams.MaxExperience);
		params = params.append('MinSalary', searchParams.MinSalary);
		params = params.append('MaxSalary', searchParams.MaxSalary);
		params = params.append('Education', searchParams.Education);
		//return this.http.get(this.API+'getJobs/',searchParams,{headers:this.headers});
		return this.http.get(this.API+'getJobs/',{params:params});
	}

	viewJob(JobId) {
		return this.http.get(this.API+'viewJob/'+JobId,{headers:this.headers});
	}

	postJob(job) {
		return this.http.post(this.API+'postJob/',job,{headers:this.headers});
	}

	editJob(job) {
		return this.http.post(this.API+'editJob/',job,{headers:this.headers});
	}

	reportJobAsSpam(CandidateId,JobId) {
		let data = {"CandidateId":CandidateId,"JobId":JobId};
		return this.http.post(this.API+'reportJobAsSpam/',data,{headers:this.headers});
	}

	getReferrals(CandidateId) {
		return this.http.get(this.API+'getReferrals/'+CandidateId,{headers:this.headers});
	}

	referColleague(referral) {
		return this.http.post(this.API+'referColleague/',referral,{headers:this.headers});
	}

	removeReferral(referralId) {
		return this.http.post(this.API+'removeReferral/',referralId,{headers:this.headers});
	}

	getWebinarInfo(WebinarId) {
		return this.http.get(this.API+'getWebinars/'+WebinarId,{headers:this.headers});
	}

	getUpcomingWebinars(CandidateId = 0) {
		return this.http.get(this.API+'getUpcomingWebinars/'+CandidateId,{headers:this.headers});
	}

	getEnrolledWebinars(CandidateId) {
		return this.http.get(this.API+'getEnrolledWebinars/'+CandidateId,{headers:this.headers});
	}

	webinarEnrollment(register) {
		return this.http.post(this.API+'webinarEnrollment/',register,{headers:this.headers});
	}

	getWebinarAssets(WebinarId) {
		return this.http.get(this.API+'getWebinarAssets/'+WebinarId,{headers:this.headers});
	}

	submitWebinarRatings(Ratings) {
		return this.http.post(this.API+'submitWebinarRatings/',Ratings,{headers:this.headers});
	}

	payCertificateFees(register) {
		return this.http.post(this.API+'payCertificateFees/',register,{headers:this.headers});
	}

	webinarRegistered(CandidateId,WebinarId) {
		return this.http.get(this.API+'webinarRegistered/'+CandidateId+'/'+WebinarId,{headers:this.headers});
	}

	webinarPaymentDone(CandidateId,WebinarId) {
		return this.http.get(this.API+'webinarPaymentDone/'+CandidateId+'/'+WebinarId,{headers:this.headers});
	}

	// changePassword(data,CandidateId) {
	// 	return this.http.post(this.API+'changePassword/'+CandidateId,data,{headers:this.headers});
	// }

	Change_Password(feedback:any){
        return this.http.post(this.API+'change_password',feedback,{headers:this.headers});
    }


	announcementApproval(CandidateId,Status,AnnouncementId) {
		let data = {"CandidateId": CandidateId,"Status":Status,"AnnouncementId":AnnouncementId};
		return this.http.post(this.API+'announcementApproval/',data,{headers:this.headers});
	}

	getUserProfile(UserSlug) {
		return this.http.get(this.API+'getUserProfile/'+UserSlug,{headers:this.headers});
	}

	getChatContacts(CandidateId) {
		return this.http.get(this.API+'getChatContacts/'+CandidateId,{headers:this.headers});
	}

	notifyUnreadMessages(CandidateId) {
		return this.http.get(this.API+'notifyUnreadMessages/'+CandidateId,{headers:this.headers});
	}

	getVideoLink(MaterialId){
		return this.http.get(this.API+'getVideoLink/'+MaterialId,{headers:this.headers});
	}

	getAnnouncements(CandidateId){
		return this.http.get(this.API+'getAnnouncements/'+CandidateId,{headers:this.headers});
	}

    getAllAnnouncements(CandidateId,filter_data){

		return this.http.get(this.API+'getAllAnnouncements/'+CandidateId+'?category='+filter_data.category+'&search_text='+filter_data.announcementtext,{headers:this.headers});
	}

	getCreditsPerReferral() {
		return this.http.get(this.API+'getCreditsPerReferral',{headers:this.headers});
	}

	getAvailableCredits(CandidateId) {
		return this.http.get(this.API+'getAvailableCredits/'+CandidateId,{headers:this.headers});
	}

	joinPremiumClub(PaymentThrough,CandidateId,SubTotal) {
		return this.http.get(this.API+'joinPremiumClub/'+PaymentThrough+'/'+CandidateId+'/'+SubTotal,{headers:this.headers});
	}

	premiumFeesPaid_RazorPay(Res,Opts,CandidateId) {
		let data = {
			'res':Res,
			'Opts': Opts
		}
		return this.http.post(this.API+'premiumClubFeesPaid/'+CandidateId,data,{headers:this.headers});
	}

	submitfeedback(data) {
		return this.http.post(this.API+'submitfeedback',data,{headers:this.headers});
	}

	getQuotes() {
		return this.http.get(this.API+'getQuotes/',{headers:this.headers});
	}

    getCources(){
        return this.http.get(this.API+'getallcources',{headers:this.headers});
    }

	submitTraining(data) {
		return this.http.post(this.API+'submitTraining',data,{headers:this.headers});
	}

	getSubmittedTrainings(CandidateId) {
		return this.http.get(this.API+'getSubmittedTrainings/'+CandidateId,{headers:this.headers});
	}

	getTrainings(TrainingId,CandidateId) {
		return this.http.get(this.API+'getTrainings/'+TrainingId+'/'+CandidateId,{headers:this.headers});
	}

	getTrainingLectures(TrainingId,CandidateId) {
		return this.http.get(this.API+'getTrainingLectures/'+TrainingId+'/'+CandidateId,{headers:this.headers});
	}

	payTrainingFees(PaymentThrough,CandidateId,SubTotal,TrainingId) {
		return this.http.get(this.API+'payTrainingFees/'+PaymentThrough+'/'+CandidateId+'/'+SubTotal+'/'+TrainingId,{headers:this.headers});
	}

	trainingFeesPaid_RazorPay(Res,Opts,CandidateId,TrainingId) {
		let data = {
			'res':Res,
			'Opts': Opts
		}
		return this.http.post(this.API+'trainingFeesPaid_RazorPay/'+CandidateId+'/'+TrainingId,data,{headers:this.headers});
	}

    logout(data){
        return this.http.post(this.API+'logout',data,{headers:this.headers});
    }

	markTrainingLectureStarted(CandidateId,DocumentId) {
		return this.http.post(this.API+'markTrainingLectureStarted/'+CandidateId+'/'+DocumentId,{headers:this.headers});
	}

	markTrainingLectureCompleted(CandidateId,DocumentId) {
		return this.http.post(this.API+'markTrainingLectureCompleted/'+CandidateId+'/'+DocumentId,{headers:this.headers});
	}

	getScheduledLectures(CandidateId,CourseId) {
		return this.http.get(this.API+'getScheduledLectures/'+CandidateId+'/'+CourseId,{headers:this.headers});
	}

    getprivilegestudentbenefits(CandidateId = 0){
        return this.http.get(this.API+'getprivilegestudentbenefits/'+CandidateId,{headers:this.headers});
    }

    lecturelikedislike(data){
        return this.http.post(this.API+'lecturelikedislikestatus',data,{headers:this.headers});
    }

    allnotes(CandidateId,TrainingId){
        return this.http.get(this.API+'getnotes/'+CandidateId+'/'+TrainingId,{headers:this.headers});
    }

    getnotedetail(NoteId){
        return this.http.get(this.API+'getnotedetail/'+NoteId,{headers:this.headers});
    }

    savenotes(data){
        return this.http.post(this.API+'savenotes',data,{headers:this.headers});
    }

    deletenote(noteid){
        return this.http.get(this.API+'removenote/'+noteid,{headers:this.headers});
    }

    generateamendmentsticket(data){
        return this.http.post(this.API+'generateamendmentsticket',data,{headers:this.headers});
    }

    savecounselling(data){
        return this.http.post(this.API+'savecounselling',data,{headers:this.headers});
    }

    allcounselling(CandidateId){
        return this.http.get(this.API+'allcounselling/'+CandidateId,{headers:this.headers});
    }

    availableforjob(CandidateId,status){
        return this.http.get(this.API+'availableforjobstatus/'+CandidateId+'/'+status,{headers:this.headers});
    }

    premiumclubdays(CandidateId){
        return this.http.get(this.API+'premiumclubremainingdays/'+CandidateId,{headers:this.headers});
    }

    // mohit add below api for free cource
    enrollmentFreeCources(CandidateId,CourseId,BatchId){
        return this.http.get(this.API+'generateEnrollmentNoFreeCources/'+CandidateId+'/'+CourseId+'/'+BatchId,{headers:this.headers});
    }
    // end


}
