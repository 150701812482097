import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class AuthService {
    token: string;
    api = environment.api;
    headers = new HttpHeaders();
    candidate: any;

    constructor(
        private http: HttpClient
    ) {
        this.headers.append('Content-Type', 'text/plain');
        this.headers.append('Access-Control-Allow-Origin', '*');
        this.headers.append('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, PATCH, DELETE');
        this.headers.append('Access-Control-Allow-Headers', "Origin, X-Requested-With, Content-Type, Accept, x-auth");
    }

    signupUser(email: string, password: string) {
    }

    login(data) {
        let seq = this.http.post(this.api+'login',data,{headers:this.headers});
        seq.subscribe((res: any) => {
            this.candidate = res;
            this.token = res.Token;
        }, err => {
            console.error('ERROR', err);
        });
        return seq;
    }

    logout() {
        this.token = null;
    }

    getToken() {
        return this.token;
    }

    getCandidateId() {
        return this.candidate.CandidateId;
    }

    isAuthenticated() {
        if(this.token) {
            return true;
        }else {
            return false;
        }
    }
}
