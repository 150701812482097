import { Injectable,OnDestroy } from '@angular/core';
import { NGXToastrService } from 'app/components/extra/toastr/toastr.service';
import { CustomService } from 'app/custom.service';

import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class NotificationsService implements OnDestroy {
	
	CandidateId: number;
	senders:any;
	interval;
	
	private subject = new Subject<any>();
	
	constructor(public toastr: NGXToastrService,  private api: CustomService) {
		console.log('Called');
		if(localStorage.getItem('candidateInfo')){
            let c = JSON.parse(localStorage.getItem('candidateInfo'));
			this.CandidateId = c.CandidateId;
			if(this.CandidateId > 0) {
				this.interval = setInterval(() => {
					this.notifyUnreadMessages(this.CandidateId);
				}, 5000);
			}
        }
	}
	
	notifyUnreadMessages(CandidateId) {
		this.api.notifyUnreadMessages(CandidateId).subscribe(
			(data:any)=>{
				if(data != '') {
					this.senders = data;
					let users = [];
					data.forEach(function (key,value) {
						users.push(key.FirstName);						
					});					
					var msg = 'You have a new message from ' + users.join(",");
					this.toastr.notifyMsg('',msg);
					this.sendMessage(this.senders);
				}
			},(error)=>{

			}
		)
	}
	
	sendMessage(senders: any) {
        this.subject.next({ senders });
    }

    clearMessages() {
        this.subject.next();
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }

    ngOnDestroy() {
    	console.log('Chat Srevice');
	  clearInterval(this.interval);
	}
}

	