<!-- <div class="alert alert-info text-white white font-medium-1 pl-5 rounded-0 m-0" *ngIf="EmailVerified == 0"> -->
	<!-- <p class="pl-3 mb-0" style="color:#FFFFFF"><i class="fa fa-envelope"></i> Please check your email for Verification Email</p> -->
<!-- </div> -->
<div class="alert alert-warning text-uppercase text-white white font-medium-1 pl-sm-0 pl-md-5 rounded-0 m-0" *ngIf="Alerts && Alerts.length > 0 && show_alert_box">
	<div class="offset-md-10 col-lg-2 text-right">
		<a href="javascript:;" (click)="show_alert_box = (show_alert_box) ? false : true" class="text-white">
			<i class="fa fa-times font-medium-1 mr-1"></i>
		</a>
	</div>
	<ul class="m-0 px-3 text-white list-unstyled font-small-3">
		<li *ngIf="Alerts[0].isVerified == 0"><i class="fa fa-envelope font-medium-1 mr-1"></i> Please check your email for Verification Email. <u>( * Didn't get email? Try checking your Spam folder too )</u></li>
		<li *ngIf="Alerts[0].RegFeesPaid == 0"><i class="fa fa-calendar font-medium-1 mr-1"></i> Dear {{ candidate_name }}, please process your payment to avail the accessibility of the qualification and portal services.</li>
        <li *ngIf="Alerts[0].ProfileApproved == 0 && Alerts[0].ProfileApprovalRequest == 0 && Alerts[0].ProfileApprovedMsg == ''"><i class="fa fa-user font-medium-1 mr-1"></i> Please complete & submit your profile for approval</li>
        <li *ngIf="Alerts[0].ProfileApprovedMsg != ''"><i class="fa fa-user font-medium-1 mr-1"></i> {{ Alerts[0].ProfileApprovedMsg }}</li>
		<li *ngIf="Alerts[0].PaymentOverdue == 1"><i class="fa fa-money font-medium-1 mr-1"></i> Your payment is overdue. Kindly pay and clear your dues.</li>
		<li *ngIf="Alerts[0].ExamMonth == 1"><i class="fa fa-calendar font-medium-1 mr-1"></i> Best of Luck for your Exams(For Regular Students).</li>
	</ul>
</div>

<!-- Navbar (Header) Starts -->
<nav class="header-navbar navbar navbar-expand-lg navbar-light bg-faded shadow-sm mb-4">
    <div class="container-fluid">
        <div class="navbar-header">
            <button type="button" class="navbar-toggle d-lg-none float-left" data-toggle="collapse" (click)="toggleSidebar()">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button>
            <span class="d-lg-none navbar-right navbar-collapse-toggle">
                <a class="open-navbar-container" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="navbarSupportedContent">
                    <i class="ft-more-vertical"></i>
                </a>
            </span>

			<div class="d-none d-md-block">
				<div class="d-flex align-items-center mt-1">
					<div class="star_premium">
						<div class="profile_pic" [class.main_preminum]="isPremium" [class.mr-2]="!isPremium">
							<img *ngIf="ProfilePic != ''" width="30" [src]="API.profilepic + ProfilePic">
						</div>
						<span class="star" *ngIf="isPremium"><i class="fa fa-star" style="color:#efae2e;"></i></span>
					</div>
					<div class="prim_data d-flex align-items-center">
						<span>Welcome {{candidate_name}},</span>
						<div class="font-bold-400 primary font-medium-3 pt-3 pt-sm-0 mb-0 d-flex" [class.ml-2]="!isPremium">
							<!-- <a *ngIf="!isPremium" routerLink="/join-premium-club" class="btn btn-gold bg-dark mb-0 mx-auto" style="color:#ffcc00"><i class="fa fa-star"></i> Join Student Premium Club</a> -->
			                <span *ngIf="isPremium" class="btn mb-0 mx-auto" style="color:#efae2e"><i class="fa fa-star"></i> Premium Club Member</span>
							<div *ngIf="isPremium" class="btn mb-0 mx-auto d-flex">

								<div class="day_dyc">
									<span> {{ PremiumExpired }} Days</span>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- For mobile  -->
			<div class="d-inline-block d-md-none mobile_candidate_info">
				<div class="row mb-2">
					<div class="col-2">
						<div class="profile_pic" [class.main_preminum]="isPremium" [class.mr-2]="!isPremium">
							<img *ngIf="ProfilePic != ''" width="30" [src]="API.candidatephotoes + ProfilePic">
							<span class="star" *ngIf="isPremium"><i class="fa fa-star" style="color:#efae2e;"></i></span>
						</div>
					</div>
					<div class="col-10">
						<span>Welcome {{candidate_name}},</span>
						<div class="prim_data d-flex align-items-center">

							<div class="font-bold-400 primary font-medium-3 pt-0 pt-sm-0 mb-0 d-flex" [class.ml-2]="!isPremium">
								<a *ngIf="!isPremium" routerLink="/join-premium-club" class="btn btn-gold bg-dark mb-0 mx-auto" style="color:#ffcc00"><i class="fa fa-star"></i> Join Student Premium Club</a>
				                <span *ngIf="isPremium" class="mb-0 mr-1" style="color: #efae2e;text-align: left;font-size: 14px;"><i class="fa fa-star"></i> Premium Club Member</span>
								<div *ngIf="isPremium" class="mb-0 mx-auto d-flex">
									<div class="day_dyc px-2">
										<span> {{ PremiumExpired }} Days</span>
									</div>
								</div>
							</div>
						</div>
					</div>

				</div>


			</div>
			<!-- End -->

        </div>
        <div class="navbar-container">
            <div class="collapse navbar-collapse" id="navbarSupportedContent" [ngbCollapse]="isCollapsed">
                <ul class="navbar-nav">
                   <!--  <li class="nav-item">
						<a class="nav-link position-relative" ngbTooltip="Todo" placement="bottom" routerLink="/todo">
							<i class="ft-clock font-medium-3 blue-grey darken-4"></i>
							<p class="d-none">To Do List</p>
						</a>
					</li> -->
					<li class="nav-item" ngbDropdown [placement]="placement">
                        <a class="nav-link position-relative" id="dropdownBasic2" ngbDropdownToggle>
                            <i class="ft-bell font-medium-3 blue-grey darken-4"></i>
                            <span class="notification badge badge-pill badge-danger" *ngIf="NotiCount > 0">{{ NotiCount }}</span>
                            <p class="d-none">Notifications</p>
                        </a>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic2" class="notification-dropdown">
                            <div class="noti-list" [perfectScrollbar]>
								<div class="col-lg-12 text-right">
									<a href="javascript:;" ngbDropdownToggle class="close mb-1"><i class="icon-close text-dark"></i></a>
								</div>
                                <div class="noti-list" [perfectScrollbar] *ngIf="Notifications && Notifications.length > 0">
									<a class="dropdown-item noti-container py-2 border-bottom border-bottom-blue-grey border-bottom-lighten-4" *ngFor="let n of Notifications" (click)="readNotification(n.NotificationId)">
										<span class="noti-wrapper" style="white-space: pre-line;">
											<div class="row">
												<div class="col-2">
													<i class="ft-bell info float-left d-block font-large-1 mt-1 mr-2"></i>
												</div>
												<div class="col-10" style="line-height:21px;">
													<span class="noti-title line-height-1 d-block text-bold-400 info">{{ n.NotificationType }}</span>
													<span class="noti-text mt-1 d-block" [innerHTML]="(n.NotificationType == 'Announcement') ? n.Subject : n.NotificationMsg"></span>
                                                    <p class="mb-0" *ngIf="n.NotificationType == 'Announcement'">{{ n.Message }}</p>
													<p class="mb-0 font-small-3 text-muted"><i class="ft-calendar"></i> {{ n.CreatedOn | date }}</p>
												</div>
											</div>
										</span>
									</a>
								</div>
								<div class="noti-list" [perfectScrollbar] *ngIf="Notifications && Notifications.length == 0">
									<h1 class="text-center mt-5">
										<i class="ft-bell display-2 text-muted"></i>
										<p class="font-medium-1 text-muted">All caught up!</p>
									</h1>
								</div>
                            </div>
                            <a routerLink="/notifications" class="noti-footer primary text-center d-block border-top border-top-blue-grey border-top-lighten-4 text-bold-400 py-1" ngbDropdownToggle>Read All Notifications</a>
                        </div>
                    </li>
                    <li class="nav-item" ngbDropdown [placement]="placement">
                        <a class="nav-link position-relative" id="dropdownBasic3" ngbDropdownToggle>
                            <i class="ft-user font-medium-3 blue-grey darken-4"></i>
                            <p class="d-none">User Settings</p>
                        </a>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic3" class="text-left">
                            <a class="dropdown-item py-1" routerLink="/pages/profile">
                                <i class="ft-edit mr-2"></i>
                                <span>My Profile</span>
                            </a>
                            <a class="dropdown-item py-1" routerLink="/pages/change-password">
                                <i class="ft-lock mr-2"></i>
                                <span>Change Password</span>
                            </a>
							<a class="dropdown-item py-1" routerLink="/inbox">
                                <i class="ft-mail mr-2"></i>
                                <span>My Inbox</span>
                            </a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="javascript:;" (click)="logout()">
                                <i class="ft-power mr-2"></i>
                                <span>Logout</span>
                            </a>
                        </div>
                    </li>
                    <!-- <li class="nav-item d-none d-lg-block">
                        <a class="nav-link position-relative notification-sidebar-toggle" (click)="toggleNotificationSidebar();"> -->
                            <!-- <i class="ft-align-left font-medium-3 blue-grey darken-4"></i> -->
                            <!-- <p class="d-none">Notifications Sidebar</p> -->
                        <!-- </a> -->
                    <!-- </li> -->

                </ul>
            </div>
        </div>
    </div>
</nav>
<!-- Navbar (Header) Ends -->
<ng-template #AnnouncementModal let-c4="close" let-d4="dismiss">
	<form>
		<div class="modal-header text-center">
			<h4 class="modal-title text-bold-400 text-center text-primary">{{ Announcement[0].Subject }}</h4>
			<button type="button" class="close" aria-label="Close" (click)="d4('Cross click')">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body text-center">
			<img src="assets/img/announcement-hero.jpg" width="50%" class="img-fluid">
			<p class="lead">{{ Announcement[0].Message }}</p>
			<a href="javascript:;" (click)="announcementApproval(1,Announcement[0].AnnouncementId)" class="btn btn-lg bg-success text-white rounded-0 mr-2">Accept</a>
			<a href="javascript:;" (click)="announcementApproval(0,Announcement[0].AnnouncementId)" class="btn btn-lg bg-danger text-white rounded-0">Reject</a>
		</div>
	</form>
</ng-template>

<!-- Mohit add for notes Open Side Menu -->
<div id="navbarSide" class="navbar-side-new cart_slider row" [class.reveal-cart] = "openNotebar == true">
	<div class="container-fluid">
		<div class="row">
			<div class="col-lg-12">
				<div class="row">
					<div class="col-lg-6 col-md-6 col-sm-6 col-6">
						<a href="javascript:;" (click)="show_addnote_form = true;" class="cart_link text-decoration-none d-flex align-items-center"><i class="icon-plus"></i><span class="ml-1">Add Note</span></a>
					</div>
					<div class="col-lg-6 col-md-6 col-sm-6 col-6 text-right">
						<a href="javascript:;" (click)="openNotebar=false;" class="close_button text-decoration-none"><i class="fa fa-times-circle"></i></a>
					</div>
				</div>
			</div>
		</div>

		<!-- Here we create a form for add notes -->
		<div class="add_edit_notes my-3" *ngIf="show_addnote_form">
			<div class="row filter-panel">
				<form  #formNotes="ngForm" (submit)="savenotes();formNotes.resetForm();">
					<div class="col-12 filter-item mb-3">
						<h6 class="text-bold-500">Title</h6>
						<input type="text" class="form-control" placeholder="Title" name="NoteTitle" [(ngModel)]="saveNotes.NoteTitle" #NoteTitle="ngModel">
						<input type="hidden" class="form-control" placeholder="Title" name="NoteId" [(ngModel)]="saveNotes.NoteId" #NoteId="ngModel">
					</div>

					<div class="col-12 filter-item mb-3">
						<h6 class="text-bold-500">Description</h6>
						<textarea class="form-control" placeholder="Description" name="NoteDescription" [(ngModel)]="saveNotes.NoteDescription" #NoteDescription="ngModel" rows="8" cols="80"></textarea>
					</div>

					<div class="col-12 filter-item mb-3">
						<button type="submit" name="btnsubmit" class="btn btn-raised btn-raised btn-primary mr-1 rounded-0">
							Submit
						</button>
						<a href="javascript:;" (click)="show_addnote_form = false;" class="btn btn btn-danger mr-1 rounded-0" style="color:#fff !important;">Cancel</a>
					</div>
				</form>
			</div>
		</div>
		<!-- End -->

		<div class="notes_available_section" *ngIf="!show_addnote_form && availableNotes">
			<div class="row">
				<div class="border-bottom my-1 w-100" *ngFor="let n of allnotes">
					<div class="col-lg-12">
						<div class="notes">
							<div class="d-flex justify-content-between">
								<div class="notes_title">{{ n.NoteTitle }}</div>
								<div class="action_notes">
									<a href="javascript:;" (click)="editnote(n.NoteId)"><i class="fa fa-edit"></i></a>
									<a href="javascript:;" (click)="removenote(n.NoteId);"><i class="fa fa-trash"></i></a>
								</div>
							</div>

							<div class="notes_date text-muted"><small><i class="fa fa-calendar"></i> {{ n.CreatedOn | date:'fullDate' }}</small></div>
							<div class="notes_desc" [innerHTML]="n.NoteDesc"></div>
						</div>
					</div>
				</div>
			</div>
		</div>


		<!-- No notes are available -->
		<div class="row" *ngIf="!show_addnote_form && !availableNotes">
			<div class="col-lg-12">
				<div class="no_notes_found">
					<div class="icon"><i class="fa fa-sticky-note"></i></div>
					<div class="msg">No notes are available.</div>
				</div>
			</div>
		</div>
		<!-- End -->
	</div>


</div>
<!-- End -->
