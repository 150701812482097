import { Component, OnInit, Input, ViewChild, OnDestroy, ElementRef, Renderer2, AfterViewInit } from "@angular/core";

import { ROUTES } from './sidebar-routes.config';
import { RouteInfo } from "./sidebar.metadata";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { customAnimations } from "../animations/custom-animations";
import { ConfigService } from '../services/config.service';
import { LayoutService } from '../services/layout.service';
import { Subscription } from 'rxjs';
import { environment } from 'environments/environment';
import { Location } from '@angular/common';


@Component({
    selector: "app-sidebar",
    templateUrl: "./sidebar.component.html",
    animations: customAnimations
})
export class SidebarComponent implements OnInit, AfterViewInit, OnDestroy {

    API = environment;
    @ViewChild('toggleIcon') toggleIcon: ElementRef;
    public menuItems: any[];
    depth: number;
    activeTitle: string;
    activeTitles: string[] = [];
    expanded: boolean;
    nav_collapsed_open = false;
    logoUrl = 'assets/img/logo.png';
    public config: any = {};
    layoutSub: Subscription;

    currentURL: any = "";
    isCoursePage: boolean = false;
    currentCourse: any = "";
    IIMTFamily: any = '';
    isPremium : boolean = false;

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
        private router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService,
        private configService: ConfigService,
        private layoutService: LayoutService,
        loc: Location,
    ) {

        if(localStorage.getItem('candidateInfo')){
            let candidateInfo = JSON.parse(localStorage.getItem('candidateInfo'));
            if(candidateInfo.isPremium == 1) {
                this.isPremium = true;
            }
            this.IIMTFamily = candidateInfo.ClaimedProfiles;
        }

        this.menuItems = ROUTES;
        router.events.subscribe(val => {

            if (loc.path() != "") {
                this.currentURL = loc.path();
                let u = this.currentURL;
                 this.currentCourse = "";
                    let basicMenu = [];

                    basicMenu.push(
                        { path: '/dashboard', title: 'Dashboard', icon: 'ft-home', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                        { path: '/pages/profile', title: 'Profile', icon: 'ft-user', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                        { path: '/inbox/support', title: 'Helpdesk', icon: 'ft-book', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                    );

                    this.menuItems = basicMenu;
            }
        });

        if (this.depth === undefined) {
            this.depth = 0;
            this.expanded = true;
        }

        this.layoutSub = layoutService.customizerChangeEmitted$.subscribe(
            options => {
                if (options) {
                    if (options.bgColor) {
                        if (options.bgColor === 'white') {
                            this.logoUrl = 'assets/img/logo-dark.png';
                        }
                        else {
                            this.logoUrl = 'assets/img/logo.png';
                        }
                    }

                    if (options.compactMenu === true) {
                        this.expanded = false;
                        this.renderer.addClass(this.toggleIcon.nativeElement, 'ft-toggle-left');
                        this.renderer.removeClass(this.toggleIcon.nativeElement, 'ft-toggle-right');
                        this.nav_collapsed_open = true;
                    }
                    else if (options.compactMenu === false) {
                        this.expanded = true;
                        //this.renderer.removeClass(this.toggleIcon.nativeElement, 'ft-toggle-left');
                        this.renderer.addClass(this.toggleIcon.nativeElement, 'ft-toggle-right');
                        this.nav_collapsed_open = false;
                    }
                }
            });
        }


        ngOnInit() {
            this.config = this.configService.templateConf;
            if (this.config.layout.sidebar.backgroundColor === 'white') {
                this.logoUrl = 'assets/img/logo-dark.png';
            }
            else {
                this.logoUrl = 'assets/img/logo.png';
            }


        }

        ngAfterViewInit() {

            setTimeout(() => {
                if (this.config.layout.sidebar.collapsed != undefined) {
                    if (this.config.layout.sidebar.collapsed === true) {
                        this.expanded = false;
                        this.renderer.addClass(this.toggleIcon.nativeElement, 'ft-toggle-left');
                        this.renderer.removeClass(this.toggleIcon.nativeElement, 'ft-toggle-right');
                        this.nav_collapsed_open = true;
                    }
                    else if (this.config.layout.sidebar.collapsed === false) {
                        this.expanded = true;
                        //this.renderer.removeClass(this.toggleIcon.nativeElement, 'ft-toggle-left');
                        this.renderer.addClass(this.toggleIcon.nativeElement, 'ft-toggle-right');
                        this.nav_collapsed_open = false;
                    }
                }
            }, 0);


        }

        ngOnDestroy() {
            if (this.layoutSub) {
                this.layoutSub.unsubscribe();
            }
        }

        toggleSlideInOut() {
            this.expanded = !this.expanded;
        }

        handleToggle(titles) {
            this.activeTitles = titles;
        }

        // NGX Wizard - skip url change
        ngxWizardFunction(path: string) {
            if (path.indexOf("forms/ngx") !== -1)
            this.router.navigate(["forms/ngx/wizard"], { skipLocationChange: false });
        }
    }
