import { Routes, RouterModule } from '@angular/router';
//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
    {
        path: 'dashboard',
        loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
        path: 'course',
        loadChildren: () => import('../../course/course.module').then(m => m.CourseModule)
    },
    {
        path: 'todo',
        loadChildren: () => import('../../todo/todo.module').then(m => m.TodoModule),

    },
    {
        path: 'notifications',
        loadChildren: () => import('../../notifications/notifications.module').then(m => m.NotificationsModule),

    },
	{
        path: 'jobs',
        loadChildren: () => import('../../jobs/jobs.module').then(m => m.JobsModule)
    },


    {
        path: 'premium-club',
        loadChildren: () => import('../../privilege-student/privilege-student.module').then(m => m.PrivilegeStudentModule)
    },

    {
        path : 'announcements',
        loadChildren: () => import('../../announcements/announcements.module').then(m => m.AnnouncementsModule)
    },


	{
        path: 'referrals',
        loadChildren: () => import('../../referrals/referrals.module').then(m => m.ReferralsModule)
    },
	{
        path: 'webinars',
        loadChildren: () => import('../../webinars/webinars.module').then(m => m.WebinarsModule)
    },
    {
        path: 'trainings',
        loadChildren: () => import('../../trainings/trainings.module').then(m => m.TrainingsModule)
    },
    {
        path: 'join-premium-club',
        loadChildren: () => import('../../join-premium-club/join-premium-club.module').then(m => m.JoinPremiumClubModule)
    },
    {
        path: 'calendar',
        loadChildren: () => import('../../calendar/calendar.module').then(m => m.CalendarsModule)
    },
    
    {
        path: 'forms',
        loadChildren: () => import('../../forms/forms.module').then(m => m.FormModule)
    },



    {
        path: 'components',
        loadChildren: () => import('../../components/ui-components.module').then(m => m.UIComponentsModule)
    },
    {
        path: 'pages',
        loadChildren: () => import('../../pages/full-pages/full-pages.module').then(m => m.FullPagesModule)
    },
    {
        path: 'cards',
        loadChildren: () => import('../../cards/cards.module').then(m => m.CardsModule)
    },

    {
        path: 'chat',
        loadChildren: () => import('../../chat/chat.module').then(m => m.ChatModule)
    },

    {
        path: 'inbox',
        loadChildren: () => import('../../inbox/inbox.module').then(m => m.InboxModule)
    },
    {
        path: 'taskboard',
        loadChildren: () => import('../../taskboard/taskboard.module').then(m => m.TaskboardModule)
    },

    {
        path: 'player',
        loadChildren: () => import('../../player/player.module').then(m => m.PlayerModule)
    }
];
